body {
    margin: 0;
    padding: 0;
    font-family: Arial;
}

.box {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1950A4;
}

.box .content {
    width: 600px;
    height: 700px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.btn {
    font-size: 16px;
    padding: 10px 15px;
    border: none;
    background-color: #1950A4;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}

.btn:hover {
    background-color: #104594;
}

.btn:active{
    background-color: #002763;
}

.reader {
    width: 80%;
    height: auto;
}

@media screen (width < 700px) {
    .box .content {
        width: 100%;
    }
}